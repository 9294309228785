import React, { useState, useEffect } from "react";
import JwtService from "../../auth/services/jwtService";
import {
  Drawer,
  Tabs,
  Tab,
  Button,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  TableContainer,
  Paper,
  ToggleButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useSelector, useDispatch } from "react-redux";
import { closeBudgetDetailDrawer } from "app/store/fuse/budgetDetailSlice";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import moment from "moment";
import { getNestedValue } from "../BudgetReport";

const detailsConfig = {
  BudgetApprovals: [
    { label: "Şirket Adı", field: "advertiserDisplayName" },
    { label: "Hesap Adı", field: "accountName" },
    { label: "Hesap ID", field: "accountId" },
    { label: "Reklamveren ID", field: "advertiserId" },
    { label: "Kampanya Adı", field: "campaignName" || "-" },
    { label: "Kampanya Durumu", field: "campaignStatus" },
    { label: "Kampanya ID", field: "campaignId" },
    { label: "Bütçe", field: "campaignBudget" },
    { label: "Para Birimi", field: "advertiser.currencyCode" },
    { label: "Kaynak", field: "source" },
    { label: "Saat Dilimi", field: "advertiser.timeZone" },
  ],
  SharedBudgetApprovals: [
    { label: "Şirket Adı", field: "advertiser.displayName" },
    { label: "Hesap Adı", field: "accountName" },
    { label: "ID", field: "id" },
    { label: "Hesap ID", field: "accountId" },
    { label: "Reklamveren ID", field: "advertiserId" },
    { label: "Bütçe ID", field: "budgetId" },
    { label: "Bütçe Tutarı", field: "amountMicros" },
    { label: "Para Birimi", field: "advertiser.currencyCode" },
    { label: "Bütçe Periyodu", field: "period" },
    { label: "Saat Dilimi", field: "advertiser.timeZone" },
  ],
};

const approvalConfig = {
  BudgetApprovals: [
    { label: "Kampanya Durumu", field: "status" },
    { label: "Bütçe Değişikliği", field: "amount_micros", isCurrency: true },
    { label: "Tarih Değişikliği", field: "date" },
    { label: "Kampanya Bütçesi", field: "budget" },
  ],
  SharedBudgetApprovals: [
    { label: "Kampanya Durumu", field: "status" },
    { label: "Bütçe Değişikliği", field: "amount_micros", isCurrency: true },
    { label: "Tarih Değişikliği", field: "date" },
    { label: "Bütçe", field: "budget" },
  ],
};

function BudgetDetailDrawer() {
  const dispatch = useDispatch();
  const { open, data, apiEndpoint } = useSelector(
    (state) =>
      state.fuse?.budgetDetail || { open: false, data: null, apiEndpoint: "" }
  );

  const [activeTab, setActiveTab] = useState(0);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [changeEventData, setChangeEventData] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [approvalSelections, setApprovalSelections] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [uniqueInsertionOrders, setUniqueInsertionOrders] = useState([]);

  const fetchLogs = async (apiEndpoint, data) => {
    if (!apiEndpoint || !data) {
      console.warn("⚠️ fetchLogs çağrıldı ama apiEndpoint veya data eksik!", {
        apiEndpoint,
        data,
      });
      return;
    }

    setLoading(true);
    let logUrl = "";

    if (apiEndpoint === "BudgetApprovals" && data.campaignId) {
      logUrl = `${process.env.REACT_APP_API_URL}/${apiEndpoint}/${data.campaignId}/Logs?pageSize=10`;
    } else if (apiEndpoint === "SharedBudgetApprovals" && data.id) {
      logUrl = `${process.env.REACT_APP_API_URL}/${apiEndpoint}/${data.id}/Logs?pageSize=10`;
    } else {
      console.warn("HATA: Uygun API URL'si oluşturulamadı!", {
        apiEndpoint,
        data,
      });
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(logUrl, {
        headers: { Authorization: `Bearer ${JwtService.getAccessToken()}` },
      });

      if (!response.ok) {
        throw new Error(`HTTP Hata! Status: ${response.status}`);
      }

      const logData = await response.json();

      const totalItems = logData.totalItems || logData.items.length || 10;

      if (totalItems > 10) {
        const fullLogUrl = logUrl.replace(
          "pageSize=10",
          `pageSize=${totalItems}`
        );

        const fullResponse = await fetch(fullLogUrl, {
          headers: { Authorization: `Bearer ${JwtService.getAccessToken()}` },
        });

        if (!fullResponse.ok) {
          throw new Error(`HTTP Hata! Status: ${fullResponse.status}`);
        }

        const fullLogData = await fullResponse.json();

        setLogs(fullLogData.items || []);
      } else {
        setLogs(logData.items || []);
      }
    } catch (err) {
      console.error("API isteğinde hata oluştu:", err);
      setLogs([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchChangeEvents = async () => {
    if (!data) return;

    setLoading(true);

    const changeEventUrl =
      apiEndpoint === "BudgetApprovals"
        ? `${process.env.REACT_APP_API_URL}/ChangeEvent?campaignId=${data.campaignId}&pageSize=1000`
        : `${process.env.REACT_APP_API_URL}/ChangeEvent?campaignBudgetId=${data.id}&pageSize=1000`;

    try {
      const response = await fetch(changeEventUrl, {
        headers: { Authorization: `Bearer ${JwtService.getAccessToken()}` },
      });

      if (!response.ok) {
        throw new Error(`HTTP Hata! Status: ${response.status}`);
      }

      const eventData = await response.json();

      const totalItems = eventData.totalItems || eventData.items.length || 10;

      if (totalItems > 10) {
        const fullChangeEventUrl = changeEventUrl.replace(
          "pageSize=10",
          `pageSize=${totalItems}`
        );

        const fullResponse = await fetch(fullChangeEventUrl, {
          headers: { Authorization: `Bearer ${JwtService.getAccessToken()}` },
        });

        if (!fullResponse.ok) {
          throw new Error(`HTTP Hata! Status: ${fullResponse.status}`);
        }

        const fullEventData = await fullResponse.json();

        setChangeEventData(fullEventData);
      } else {
        setChangeEventData(eventData);
      }
    } catch (err) {
      console.error("API isteğinde hata oluştu:", err);
      setChangeEventData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!data || !apiEndpoint) return;

    setChangeEventData(null);
    setLogs([]);
    let getLogs = async () => {
      await fetchLogs(apiEndpoint, data);
    };
    getLogs();
    fetchChangeEvents();
  }, [data, apiEndpoint]);

  useEffect(() => {
    if (activeTab === 1 && changeEventData) {
      console.log(
        "Change Event Data (Approval Status Tab): ",
        changeEventData.items
      );
    }
  }, [activeTab, changeEventData]);

  useEffect(() => {
    if (activeTab === 2 && logs.length > 0) {
      setFilterValue("");
      setUniqueInsertionOrders([]);
      const uniqueOrders = [
        ...new Set(logs.map((log) => log.insertionOrderName)),
      ];
      setUniqueInsertionOrders(uniqueOrders);
    }
  }, [activeTab, logs, data]);

  // const handleApproval = async (status, itemId) => {
  //   setLoading(true);
  //   try {
  //     await fetch(`${process.env.REACT_APP_API_URL}/ChangeEvent`, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${JwtService.getAccessToken()}`,
  //       },
  //       body: JSON.stringify({ id: itemId, isAccepted: status }),
  //     });
  //     fetchChangeEvents();
  //     let getLogs = async () => {
  //       await fetchLogs(apiEndpoint, data);
  //     };
  //     getLogs();
  //   } catch (err) {
  //     console.error("Error updating approval: ", err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const applySelections = async () => {
    const bulkPayload = Object.entries(approvalSelections).map(
      ([id, isAccepted]) => ({
        id: parseInt(id),
        isAccepted,
      })
    );

    if (bulkPayload.length === 0) return;

    setLoading(true);
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/ChangeEvent/BulkUpdate`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${JwtService.getAccessToken()}`,
        },
        body: JSON.stringify(bulkPayload),
      });
      await fetchChangeEvents();

      await fetchLogs(apiEndpoint, data);
    } catch (err) {
      console.error("Toplu onay hatası: ", err);
    } finally {
      setLoading(false);
    }
  };

  // const applySelections = async () => {
  //   Object.entries(approvalSelections).forEach(([itemId, isAccepted]) => {
  //     handleApproval(isAccepted, itemId);
  //   });

  //   fetchChangeEvents();
  //   fetchLogs();
  // };

  const handleSelectionChange = (itemId, status) => {
    setApprovalSelections((prev) => ({
      ...prev,
      [itemId]: status,
    }));
  };

  useEffect(() => {
    if (changeEventData?.items) {
      const initialSelections = changeEventData.items.reduce((acc, item) => {
        if (item.isAccepted === null) {
          acc[item.id] = true;
        }

        return acc;
      }, {});
      setApprovalSelections(initialSelections);
    }
  }, [changeEventData]);

  const formatCurrency = (value) => {
    if (isNaN(value) || value === null || value === undefined) return "-";
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
    }).format(value);
  };

  const formatDate = (startDate, endDate) => {
    const formattedStart = startDate
      ? moment(startDate).format("DD.MM.YYYY")
      : "-";
    const formattedEnd = endDate ? moment(endDate).format("DD.MM.YYYY") : "-";
    return `${formattedStart} - ${formattedEnd}`;
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmApproval = () => {
    applySelections();
    handleCloseDialog();
  };

  const showApprovalTab = data?.isAccepted === null;

  const tabs = [
    {
      label:
        apiEndpoint === "BudgetApprovals"
          ? "Kampanya Detayları"
          : "Bütçe Detayları",
    },
    ...(showApprovalTab ? [{ label: "Onay Durumu" }] : []),
    {
      label: "Onay Geçmişi",
    },
  ];

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => dispatch(closeBudgetDetailDrawer())}
      sx={{ width: "40%" }}
    >
      <Tabs
        value={activeTab}
        onChange={(e, newVal) => setActiveTab(newVal)}
        variant="fullWidth"
        sx={{ position: "sticky", top: 0, background: "#f5f5f5", zIndex: 1 }}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
        <IconButton
          sx={{
            backgroundColor: "#0f172a",
            color: "#fff",
            marginLeft: "auto",
            padding: "8px",
            borderRadius: "0px",
            "&:hover": {
              backgroundColor: "red",
            },
          }}
          onClick={() => dispatch(closeBudgetDetailDrawer())}
          hoverColor="red"
        >
          <CloseIcon sx={{ color: "#fff" }} />
        </IconButton>
      </Tabs>

      {loading ? (
        <FuseLoading />
      ) : (
        <div className="p-4">
          {activeTab === 0 && (
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableBody>
                  {detailsConfig[apiEndpoint]?.map((item, index) => {
                    const value = getNestedValue(
                      data,
                      item.field,
                      changeEventData?.items?.[0]
                    );
                    return (
                      <TableRow
                        key={item.field}
                        sx={{
                          backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                        }}
                      >
                        <TableCell>
                          <strong>{item.label}</strong>
                        </TableCell>
                        <TableCell
                          sx={{
                            maxWidth: "150px",
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                          }}
                        >
                          {value}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {showApprovalTab && activeTab === 1 && changeEventData?.items && (
            <>
              <div className="flex flex-col">
                <Typography
                  sx={{
                    mb: 1,
                    mt: 2,
                    border: "2px solid #0f172a",
                    px: 2,
                    py: 2,
                  }}
                >
                  {apiEndpoint === "BudgetApprovals"
                    ? `Kampanya ID: ${data?.campaignId}`
                    : `Bütçe ID: ${data?.budgetId}`}
                </Typography>
                <Typography
                  sx={{
                    mb: 2,
                    border: "2px solid #0f172a",
                    px: 2,
                    py: 2,
                    wordBreak: "break-word",
                  }}
                >
                  {apiEndpoint === "BudgetApprovals"
                    ? `Kampanya Adı: ${data?.campaignName}`
                    : `Bütçe Adı: ${data?.name}`}
                </Typography>
              </div>
              <TableContainer
                component={Paper}
                sx={{ maxHeight: "95vh", overflow: "auto" }}
              >
                <Table>
                  <TableHead
                    sx={{
                      position: "sticky",
                      zIndex: 2,
                      top: 0,
                      backgroundColor: "#0f172a",
                    }}
                  >
                    <TableRow
                      sx={{
                        position: "sticky",
                        zIndex: 1,
                        top: 0,
                        backgroundColor: "#0f172a",
                      }}
                    >
                      <TableCell sx={{ color: "white" }}>
                        <strong>Reklam Grup Adı / ID</strong>
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        <strong>Onaylanma Sebebi</strong>
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        <strong>Değişen Alan</strong>
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        <strong>Alan</strong>
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        <strong>Eski Veri</strong>
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        <strong>Yeni Veri</strong>
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        <strong>Onay Durumu</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {changeEventData.items.map((item, index) => {
                      const oldData = JSON.parse(item.oldData || "{}");
                      const newData = JSON.parse(item.newData || "{}");
                      const changedField = item.changedFields;
                      const isAccepted = approvalSelections[item.id];
                      const adGroupName = item?.adGroupName || "-";
                      const adGroupId = item?.adGroupId || "-";
                      const changeResourceType =
                        item?.changeResourceType || "-";
                      const approvalReason = item?.approvalReason || "-";

                      const relevantFields = approvalConfig[
                        apiEndpoint
                      ]?.filter(
                        (configItem) => configItem.field === changedField
                      );

                      if (!relevantFields || relevantFields.length === 0)
                        return null;

                      return (
                        <TableRow
                          key={index}
                          sx={{
                            border: "2px solid #ddd",
                            padding: "8px",
                            backgroundColor:
                              index % 2 === 0 ? "#f9f9f9" : "#fff",
                          }}
                        >
                          <TableCell
                            sx={{
                              maxWidth: "150px",
                              whiteSpace: "normal",
                              wordBreak: "break-word",
                            }}
                          >{`${adGroupName} / ${adGroupId}`}</TableCell>
                          <TableCell
                            sx={{
                              maxWidth: "150px",
                              whiteSpace: "normal",
                              wordBreak: "break-word",
                            }}
                          >
                            {approvalReason}
                          </TableCell>
                          <TableCell
                            sx={{
                              maxWidth: "150px",
                              whiteSpace: "normal",
                              wordBreak: "break-word",
                            }}
                          >
                            {changeResourceType}
                          </TableCell>

                          {relevantFields.map((configItem) => {
                            let oldValue = oldData[configItem.field] || "-";
                            let newValue = newData[configItem.field] || "-";

                            if (configItem.field === "date") {
                              oldValue = formatDate(
                                oldData.startDate,
                                oldData.endDate
                              );
                              newValue = formatDate(
                                newData.startDate,
                                newData.endDate
                              );
                            }

                            if (configItem.field === "amount_micros") {
                              const currencyCode =
                                item?.advertiser?.currencyCode;
                              oldValue = `${formatCurrency(
                                oldValue
                              )} ${currencyCode}`;
                              newValue = `${formatCurrency(
                                newValue
                              )} ${currencyCode}`;
                            }

                            if (configItem.field === "budget") {
                              const currency = oldData?.budget?.currency;
                              const oldBudget = oldData?.budget?.old_value;
                              const newBudget = newData?.budget?.new_value;
                              oldValue = `${formatCurrency(
                                oldBudget
                              )} ${currency}`;
                              newValue = `${formatCurrency(
                                newBudget
                              )} ${currency}`;
                            }

                            return (
                              <>
                                <TableCell
                                  sx={{
                                    maxWidth: "150px",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <strong>{configItem.label}</strong>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    maxWidth: "150px",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {oldValue}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    maxWidth: "150px",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {newValue}
                                </TableCell>
                              </>
                            );
                          })}

                          {item?.isAccepted === null ? (
                            <TableCell align="center">
                              <ToggleButton
                                value={true}
                                selected={approvalSelections[item.id] === true}
                                onClick={() =>
                                  handleSelectionChange(item.id, true)
                                }
                                sx={{
                                  backgroundColor:
                                    approvalSelections[item.id] === true
                                      ? "#4CAF50"
                                      : "transparent",
                                  color:
                                    approvalSelections[item.id] === true
                                      ? "white"
                                      : "grey",
                                  borderRadius: "8px",
                                  border: "1px solid grey",
                                  "&.Mui-selected": {
                                    backgroundColor: "#4CAF50 !important",
                                    color: "white !important",
                                  },
                                }}
                              >
                                <CheckIcon />
                              </ToggleButton>

                              <ToggleButton
                                value={false}
                                selected={approvalSelections[item.id] === false}
                                onClick={() =>
                                  handleSelectionChange(item.id, false)
                                }
                                sx={{
                                  backgroundColor:
                                    approvalSelections[item.id] === false
                                      ? "#F44336"
                                      : "transparent",
                                  color:
                                    approvalSelections[item.id] === false
                                      ? "white"
                                      : "grey",
                                  borderRadius: "8px",
                                  border: "1px solid grey",
                                  "&.Mui-selected": {
                                    backgroundColor: "#F44336 !important",
                                    color: "white !important",
                                  },
                                }}
                              >
                                <CloseIcon />
                              </ToggleButton>
                            </TableCell>
                          ) : (
                            <TableCell align="center">
                              {item?.isAccepted === true ? (
                                <Typography
                                  sx={{ color: "#4CAF50", fontWeight: "bold" }}
                                >
                                  Onaylandı
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{ color: "#F44336", fontWeight: "bold" }}
                                >
                                  Reddedildi
                                </Typography>
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableRow>
                    <TableCell colSpan={4} align="left">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenDialog}
                      >
                        Seçimlerimi Uygula
                      </Button>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </>
          )}
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Onay Gerekli</DialogTitle>
            <DialogContent>
              <p>
                Yaptığınız değişiklikleri onaylamak istediğinizden emin misiniz?
              </p>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleConfirmApproval}
                sx={{
                  backgroundColor: "green",
                  color: "white",
                  "&:hover": { backgroundColor: "#388E3C" },
                }}
              >
                Evet
              </Button>
              <Button
                onClick={handleCloseDialog}
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  "&:hover": { backgroundColor: "#D32F2F" },
                }}
              >
                Hayır
              </Button>
            </DialogActions>
          </Dialog>

          {activeTab === (showApprovalTab ? 2 : 1) && logs.length > 0 ? (
            <div className="p-4">
              <Autocomplete
                options={uniqueInsertionOrders}
                getOptionLabel={(option) => option}
                value={filterValue}
                onChange={(e, newValue) => setFilterValue(newValue || "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Insertion Order Adı Seçin"
                    variant="outlined"
                  />
                )}
                sx={{ minWidth: 250, marginBottom: 2 }}
              />
              <TableContainer
                component={Paper}
                sx={{ maxHeight: "95vh", overflow: "auto" }}
              >
                <Table>
                  <TableHead
                    sx={{
                      position: "sticky",
                      zIndex: 2,
                      top: 0,
                      backgroundColor: "#0f172a",
                    }}
                  >
                    <TableRow
                      sx={{
                        position: "sticky",
                        zIndex: 1,
                        top: 0,
                        backgroundColor: "#0f172a",
                      }}
                    >
                      <TableCell sx={{ color: "white" }}>
                        Reklam Grup Adı / ID
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        Onaylanma Sebebi
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>Kullanıcı</TableCell>
                      <TableCell sx={{ color: "white" }}>Tarih</TableCell>
                      <TableCell sx={{ color: "white" }}>Eski Veri</TableCell>
                      <TableCell sx={{ color: "white" }}>Yeni Veri</TableCell>
                      <TableCell sx={{ color: "white" }}>Onay Durumu</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logs
                      .filter(
                        (log) =>
                          filterValue === "" ||
                          (log.insertionOrderName &&
                            log.insertionOrderName
                              .toLowerCase()
                              .includes(filterValue.toLowerCase()))
                      )
                      .map((log, index) => {
                        let updatedDate = "N/A";
                        let isAcceptedText = "N/A";
                        let approvalReason = "N/A";
                        let oldValue = "N/A";
                        let newValue = "N/A";

                        try {
                          if (!log.newValues) {
                            console.warn(
                              `Warning: newValues eksik - Log ID: ${log.id}`
                            );
                            return null;
                          }

                          const newValues = JSON.parse(log.newValues);
                          updatedDate = newValues.UpdatedDate
                            ? moment(newValues.UpdatedDate).format(
                                "DD.MM.YYYY HH:mm"
                              )
                            : "N/A";

                          approvalReason = newValues.ApprovalReason
                            ? newValues.ApprovalReason
                            : "-";

                          isAcceptedText = newValues.IsAccepted
                            ? "Onaylandı"
                            : "Reddedildi";

                          if (!log.primaryKey) {
                            console.warn(
                              `Warning: primaryKey eksik - Log ID: ${log.id}`
                            );
                            return null;
                          }

                          const primaryKey = JSON.parse(log.primaryKey || "{}");
                          const logItemId = primaryKey?.Id;

                          const matchedEvent = changeEventData?.items.find(
                            (item) => item.id == logItemId
                          );

                          if (!matchedEvent) {
                            console.warn(
                              `Warning: Log ID ${logItemId} için eşleşen event bulunamadı.`
                            );
                          } else {
                            const newData = JSON.parse(
                              matchedEvent.newData || "{}"
                            );
                            const oldData = JSON.parse(
                              matchedEvent.oldData || "{}"
                            );
                            const changedField = matchedEvent.changedFields;

                            if (changedField === "amount_micros") {
                              const currencyCode =
                                matchedEvent?.advertiser?.currencyCode || "N/A";
                              oldValue = `${formatCurrency(
                                oldData.amount_micros
                              )} ${currencyCode}`;
                              newValue = `${formatCurrency(
                                newData.amount_micros
                              )} ${currencyCode}`;
                            } else if (changedField === "status") {
                              oldValue = oldData.status || "N/A";
                              newValue = newData.status || "N/A";
                            } else if (changedField === "budget") {
                              const currency = oldData.budget.currency || "N/A";
                              oldValue = `${formatCurrency(
                                oldData.budget.old_value
                              )} ${currency}`;
                              newValue = `${formatCurrency(
                                newData.budget.new_value
                              )} ${currency}`;
                            } else if (changedField === "date") {
                              oldValue = formatDate(
                                oldData.startDate,
                                oldData.endDate
                              );
                              newValue = formatDate(
                                newData.startDate,
                                newData.endDate
                              );
                            }
                          }
                        } catch (error) {
                          console.error("Error parsing log data:", error);
                        }

                        return (
                          <TableRow
                            key={log.id}
                            sx={{
                              backgroundColor:
                                index % 2 === 0 ? "#f9f9f9" : "#fff",
                            }}
                          >
                            <TableCell
                              sx={{
                                maxWidth: "150px",
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                              }}
                            >
                              {`${log.insertionOrderName} / ${log.insertionOrderId}` ||
                                "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                maxWidth: "150px",
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                              }}
                            >
                              {approvalReason}
                            </TableCell>
                            <TableCell
                              sx={{
                                maxWidth: "150px",
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                              }}
                            >
                              {log.userName}
                            </TableCell>
                            <TableCell
                              sx={{
                                maxWidth: "150px",
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                              }}
                            >
                              {updatedDate}
                            </TableCell>
                            <TableCell
                              sx={{
                                maxWidth: "150px",
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                              }}
                            >
                              {oldValue}
                            </TableCell>
                            <TableCell
                              sx={{
                                maxWidth: "150px",
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                              }}
                            >
                              {newValue}
                            </TableCell>
                            <TableCell
                              sx={{
                                maxWidth: "150px",
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                              }}
                            >
                              {isAcceptedText}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            activeTab === (showApprovalTab ? 2 : 1) &&
            logs.length === 0 && (
              <Typography variant="body2" className="p-4">
                No logs available.
              </Typography>
            )
          )}
        </div>
      )}
    </Drawer>
  );
}

export default BudgetDetailDrawer;

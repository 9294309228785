import React, { useEffect, useState, useCallback } from "react";
import JwtService from "../auth/services/jwtService";
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Button,
  TableSortLabel,
  Tooltip,
  Typography,
  TextField,
  TableContainer,
  Paper,
  Autocomplete,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { openBudgetDetailDrawer } from "app/store/fuse/budgetDetailSlice";
import BudgetDetailDrawer from "./Drawer/BudgetDetailDrawer";
import FuseLoading from "@fuse/core/FuseLoading";

export const getNestedValue = (obj, field, fallbackObj = null) => {
  if (!obj) return "-";

  if (typeof field === "function") {
    return field(obj);
  }

  let value = field
    .split(".")
    .reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : null),
      obj
    );

  if (value === null && fallbackObj) {
    value = field
      .split(".")
      .reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : "-"),
        fallbackObj
      );
  }

  if (["oldBudget", "newBudget", "amountMicros"].includes(field)) {
    return formatCurrency(value);
  }

  if (["campaignName"].includes(field)) {
    return formatCampaignName(value);
  }

  if (field === "approvalDeadline") {
    return <TimeRemainingCell deadline={value} />;
  }

  return value !== null ? value : "-";
};

const formatCampaignName = (value) => {
  if (!value) return "-";
  const cleanName = value.replace(/[^a-zA-Z0-9ğüşıöçĞÜŞİÖÇ\s]/g, " ");
  const words = cleanName.trim().split(/\s+/);
  if (words.length <= 6) {
    return value;
  }
  const shortenedName = `${words.slice(0, 4).join(" ")}... ${words.slice(-2)}`;
  return shortenedName;
};

const TimeRemainingCell = ({ deadline }) => {
  const timeRemaining = useTimeRemaining(deadline);
  return <span>{timeRemaining}</span>;
};

const useTimeRemaining = (deadline) => {
  const [timeRemaining, setTimeRemaining] = useState("");

  useEffect(() => {
    if (!deadline) {
      setTimeRemaining("-");
      return;
    }

    const updateRemainingTime = () => {
      const deadlineDate = new Date(deadline);
      const now = new Date();
      const diffMs = deadlineDate - now;

      if (diffMs <= 0) {
        setTimeRemaining("Süre doldu");
        return;
      }

      const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

      setTimeRemaining(`${diffHours} saat ${diffMinutes} dakika kaldı`);
    };

    updateRemainingTime();
    const interval = setInterval(updateRemainingTime, 60000);

    return () => clearInterval(interval);
  }, [deadline]);

  return timeRemaining;
};

const formatCurrency = (value) => {
  if (isNaN(value) || value === null || value === undefined) return "-";
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
  }).format(value);
};

const ApprovalStatusCell = ({ item }) => {
  let countText = `${item.acceptedCount || 0} Adet`;
  const reasonText = item.approvalReason || "-";
  const status = item.isAccepted;
  const firstChangeDate = item?.approvalChangeDateTime
    ? new Date(item.approvalChangeDateTime).toLocaleString("tr-TR")
    : "-";

  let bg = "";
  let color = "";

  if (status === true) {
    countText = "Onaylandı";
    bg = "#c8e6c9";
    color = "#2e7d32";
  } else if (status === false) {
    countText = "Reddedildi";
    bg = "#ffcdd2";
    color = "#c62828";
  } else {
    bg = "#fff3cd";
    color = "#856404";
  }

  return (
    <TableCell
      style={{
        borderBottom: "3px solid #0f172a",
        backgroundColor: bg,
        color,
        fontWeight: "bold",
        textAlign: "center",
        whiteSpace: "pre-line",
      }}
    >
      {countText}
      <br />
      {reasonText}
      <br />
      {firstChangeDate}
    </TableCell>
  );
};

function BudgetReport({ apiEndpoint, columns }) {
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [advertisers, setAdvertisers] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sourceFilter, setSourceFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const fetchAdvertisers = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/${apiEndpoint}/Advertisers:list?status=1`,
        {
          headers: { Authorization: `Bearer ${JwtService.getAccessToken()}` },
        }
      );
      const result = await res.json();
      setAdvertisers(result);
    } catch (err) {
      console.error("Şirket listesi hatası:", err);
    }
  };

  const fetchCampaigns = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/BudgetApprovals/Campaigns:list`,
        {
          headers: { Authorization: `Bearer ${JwtService.getAccessToken()}` },
        }
      );
      const result = await res.json();
      setCampaigns(result);
    } catch (err) {
      console.error("Kampanya listesi hatası:", err);
    }
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        pageSize: pageSize,
        pageIndex: pageIndex,
        ...(searchTerm && { SearchKey: searchTerm }),
        ...(selectedAdvertiser && {
          advertiserId: selectedAdvertiser?.advertiserId,
        }),
        ...(selectedCampaign && {
          campaignId: selectedCampaign?.campaignId,
        }),
        ...(sourceFilter && { source: sourceFilter }),
        ...(statusFilter !== "" && {
          isAccepted:
            statusFilter === "null"
              ? null
              : statusFilter === "true"
              ? true
              : false,
        }),
      });

      const response = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/${apiEndpoint}?${queryParams.toString()}`,
        { headers: { Authorization: `Bearer ${JwtService.getAccessToken()}` } }
      );

      const result = await response.json();
      setData(result.items);
      setTotalPages(result.totalPages);
      console.log("fetched data: ", result.items);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [
    apiEndpoint,
    pageSize,
    pageIndex,
    searchTerm,
    sourceFilter,
    statusFilter,
    selectedAdvertiser,
    selectedCampaign,
  ]);

  useEffect(() => {
    setData([]);
    setSearchTerm("");
    setPageIndex(1);
    setSelectedAdvertiser(null);
    setSelectedCampaign(null);
  }, [apiEndpoint]);

  useEffect(() => {
    fetchAdvertisers();
    fetchCampaigns();
  }, []);

  useEffect(() => {
    fetchData();
  }, [
    selectedAdvertiser,
    selectedCampaign,
    sourceFilter,
    statusFilter,
    pageIndex,
    pageSize,
    searchTerm,
    apiEndpoint,
  ]);

  useEffect(() => {
    setPageIndex(1);
  }, [searchTerm, selectedAdvertiser, selectedCampaign]);

  const handleRequestSort = (property) => {
    const isAsc = order.id === property && order.direction === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder({ id: property, direction });
    sortData(property, direction);
  };

  const sortData = (property, direction) => {
    const sorted = [...data].sort((a, b) => {
      const aValue = getNestedValue(a, property) || "";
      const bValue = getNestedValue(b, property) || "";

      if (!isNaN(aValue) && !isNaN(bValue)) {
        return direction === "asc" ? aValue - bValue : bValue - aValue;
      }
      return direction === "asc"
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    });
    setData(sorted);
  };

  const dispatch = useDispatch();

  const handleRowClick = (row) => {
    dispatch(
      openBudgetDetailDrawer({
        data: { ...row },
        changeEventData: null,
        apiEndpoint,
      })
    );
  };

  const isDrawerOpen = useSelector((state) => state.fuse?.budgetDetail?.open);

  useEffect(() => {
    if (!isDrawerOpen) {
      fetchData();
    }
  }, [isDrawerOpen]);

  return (
    <div className="px-10 py-10 max-w-7xl mx-auto">
      <div className="flex justify-between items-center mb-4 gap-4">
        <TextField
          label="Ara (Şirket adı, Kampanya Adı/ID)"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setSelectedAdvertiser(null);
            setSelectedCampaign(null);
            setPageIndex(1);
          }}
        />

        <Autocomplete
          options={advertisers}
          getOptionLabel={(option) => option.displayName || ""}
          value={selectedAdvertiser}
          onChange={(_, value) => {
            setSearchTerm("");
            setSelectedAdvertiser(value);
            setSelectedCampaign(null);
            setPageIndex(1);
            if (value) fetchCampaigns(value.advertiserId);
            else setCampaigns([]);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Şirket Seçin" variant="outlined" />
          )}
          sx={{ width: "33%" }}
        />

        <Autocomplete
          options={campaigns}
          getOptionLabel={(option) => option.displayName || ""}
          value={selectedCampaign}
          onChange={(_, value) => {
            setSelectedCampaign(value);
            setPageIndex(1);
          }}
          disabled={apiEndpoint === "SharedBudgetApprovals"}
          renderInput={(params) => (
            <TextField {...params} label="Kampanya Seçin" variant="outlined" />
          )}
          sx={{ width: "33%" }}
        />
      </div>

      <div className="flex flex-row-reverse gap-6 items-center mb-4">
        <select
          id="pageSize"
          value={pageSize}
          onChange={(e) => setPageSize(parseInt(e.target.value))}
          className="px-4 py-2 border border-gray-300 rounded cursor-pointer"
        >
          {[10, 25, 50, 100].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
        <select
          value={statusFilter}
          onChange={(e) => {
            setStatusFilter(e.target.value);
            setPageIndex(1);
          }}
          className="px-4 py-2 border border-gray-300 rounded cursor-pointer"
        >
          <option value="">Tüm Onay Durumları</option>
          <option value="true">Onaylandı</option>
          <option value="false">Reddedildi</option>
          <option value="null">Onay bekliyor</option>
        </select>
        <select
          value={sourceFilter}
          onChange={(e) => {
            setSourceFilter(e.target.value);
            setPageIndex(1);
          }}
          className="px-4 py-2 border border-gray-300 rounded cursor-pointer"
        >
          <option value="">Tüm Kaynaklar</option>
          <option value="Meta">Meta</option>
          <option value="GoogleAds">Google Ads</option>
        </select>
      </div>
      {loading ? (
        <div className="flex justify-center py-20">
          <FuseLoading />
        </div>
      ) : data?.length > 0 ? (
        <TableContainer
          component={Paper}
          className="max-h-[95vh] overflow-auto"
        >
          <Table stickyHeader className="min-w-full">
            <TableHead className="bg-gray-100">
              <TableRow>
                {columns.map(({ label, field }) => (
                  <TableCell
                    key={field}
                    align="center"
                    style={{
                      position: "sticky",
                      top: 0,
                      background: "#f5f5f5",
                      zIndex: 2,
                    }}
                  >
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(item)}
                  style={{ cursor: "pointer" }}
                >
                  {columns.map(({ label, field }, i) => {
                    if (
                      label === "Onay Durumu" &&
                      typeof field === "function" &&
                      field.toString().includes("acceptedCount")
                    ) {
                      return <ApprovalStatusCell key={i} item={item} />;
                    }
                    return (
                      <TableCell
                        key={field}
                        align="center"
                        sx={{
                          borderBottom: "3px solid #0f172a",
                          maxWidth: "250px",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                      >
                        {getNestedValue(item, field) ||
                          data[item.campaignId]?.[field]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body2" align="center" className="p-4">
          Gösterilecek veri bulunamadı.
        </Typography>
      )}
      <div className="flex gap-4 justify-center mt-6 items-center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setPageIndex((prev) => prev - 1)}
          disabled={pageIndex === 1}
        >
          Previous
        </Button>
        <span>{`Page ${pageIndex} of ${totalPages}`}</span>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setPageIndex((prev) => prev + 1)}
          disabled={pageIndex === totalPages}
        >
          Next
        </Button>
      </div>
      <BudgetDetailDrawer />
    </div>
  );
}

export default BudgetReport;

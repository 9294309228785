import { createSlice } from "@reduxjs/toolkit";

const budgetDetailDrawerSlice = createSlice({
  name: "budgetDetail",
  initialState: {
    open: false,
    data: null,
    apiEndpoint: "",
  },
  reducers: {
    openBudgetDetailDrawer: (state, action) => {
      console.log("Dispatch Triggered: ", action.payload);
      state.open = true;
      state.data = action.payload.data || null;
      state.apiEndpoint = action.payload.apiEndpoint;
    },
    closeBudgetDetailDrawer: (state) => {
      state.open = false;
      state.data = null;
      state.apiEndpoint = "";
    },
  },
});

export const { openBudgetDetailDrawer, closeBudgetDetailDrawer } =
  budgetDetailDrawerSlice.actions;
export default budgetDetailDrawerSlice.reducer;

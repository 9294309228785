import FuseScrollbars from "@fuse/core/FuseScrollbars";
import Typography from "@mui/material/Typography";
import withReducer from "app/store/withReducer";
import { useSnackbar } from "notistack";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { useState } from "react";
import reducer from "../../theme-layouts/shared-components/notificationPanel/store";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  closeNotificationPanel,
  selectNotificationPanelState,
} from "../../theme-layouts/shared-components/notificationPanel/store/stateSlice";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import jwtService from "../../auth/services/jwtService";
import { Divider, FormControlLabel, RadioGroup } from "@mui/material";
import { resetNavigation } from "app/store/fuse/navigationSlice";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import ResponseMessage from "../Components/ResponseMessage";

function AdvertisersDrawer(props) {
  const dispatch = useDispatch();
  const state = useSelector(selectNotificationPanelState);
  const [loading, setLoading] = useState(false);
  const statusOptions = [
    { value: "0", label: "Pasif" },
    { value: "1", label: "Aktif" },
  ];
  const [countries, setCountries] = useState([]);
  const [status, setStatus] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [responseShow, setResponseShow] = useState(0);

  const getCountries = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Countries?pageIndex=1&pageSize=250`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      setCountries(data.items);
    } catch (error) {
      console.log(error);
    }
  };
  const [advertiserCategories, setAdvertiserCategories] = useState([]);
  const [advertiserData, setAdvertiserData] = useState({});
  const [disabledInput, setDisabledInput] = useState(false);
  const [metaSettings, setMetaSettings] = useState({
    dailyLimitAmount: "",
    dailyLimitApprovalTimeout: "",
    percentageLimitAmount: "",
    percentageLimitApprovalTimeout: "",
    installationApprovalTimeout: "",
    metricChangeApprovalTimeout: "",
  });
  const [googleSettings, setGoogleSettings] = useState({
    dailyLimitAmount: "",
    dailyLimitApprovalTimeout: "",
    percentageLimitAmount: "",
    percentageLimitApprovalTimeout: "",
    installationApprovalTimeout: "",
    metricChangeApprovalTimeout: "",
  });

  // Get advertiser data and set to advertiserData state.
  const getAdvertiserData = async () => {
    try {
      setLoading(true);

      if (state?.item?.advertiserId) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Advertisers/${state?.item?.advertiserId}`,
          {
            headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` },
          }
        );
        const data = await response.json();
        setAdvertiserData(data);
        setDisabledInput(true);
        setStatus(advertiserData.status.toString());
      } else {
        setAdvertiserData({
          timeZone: "Asia/Jerusalem",
          currencyCode: "TRY",
        });
        setDisabledInput(false);
        setStatus("1");
      }

      //   Below, I will change with useEffect onMount.
      await getIngageTagsOptions();
      await getCategoryOptions();
      await getAdvertiserCategories();
      await getCountries();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Get advertiser settings

  const getAdvertiserSettings = async () => {
    if (!state?.item?.advertiserId) return;

    try {
      const [metaRes, googleRes] = await Promise.all([
        fetch(
          `${process.env.REACT_APP_API_URL}/Advertisers/${state?.item?.advertiserId}/Settings?source=Meta`,
          {
            headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` },
          }
        ),
        fetch(
          `${process.env.REACT_APP_API_URL}/Advertisers/${state?.item?.advertiserId}/Settings?source=GoogleAds`,
          {
            headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` },
          }
        ),
      ]);

      const metaData = await metaRes.json();
      const googleData = await googleRes.json();
      setMetaSettings(metaData);
      setGoogleSettings(googleData);
      console.log("Meta settings: ", metaData);
      console.log("Google settings: ", googleData);
    } catch (err) {
      console.error("Ayarlar çekilirken hata: ", err);
    }
  };

  // Get changes on data and set to advertiserData state.
  const handleChangeAdvertiserData = (e, newValue, name) => {
    const updatedData = name
      ? { ...advertiserData, [name]: newValue }
      : { ...advertiserData, [e.target.name]: e.target.value };
    setAdvertiserData(updatedData);
  };

  useEffect(() => {
    console.log("girdi", advertiserData.status);
    setStatus(advertiserData.status); // Sayıyı string'e dönüştür
  }, [advertiserData]);

  function handleClose() {
    dispatch(closeNotificationPanel());
  }

  // Send advertiser data to db.
  const sendAdvertiserData = async () => {
    setResponseShow(false);
    try {
      setLoading(true);
      if (state?.item?.advertiserId) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Advertisers/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtService.getAccessToken()}`,
            },
            method: "PUT",
            body: JSON.stringify({
              ...advertiserData,
              tagIds: advertiserData.ingageTags.map((item) => item.tagId),
              countryIds: advertiserData.countries.map(
                (item) => item.countryId
              ),
              categoryIds: advertiserData.categories.map(
                (item) => item.categoryId
              ),
              advertiserCategoryIds: advertiserData.categories.map(
                (item) => item.categoryId
              ),
            }),
          }
        );
        const data = await response.json();

        await fetch(
          `${process.env.REACT_APP_API_URL}/Advertisers/${state.item.advertiserId}/BulkUpdateSettings`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtService.getAccessToken()}`,
            },
            body: JSON.stringify([
              { ...metaSettings, source: "Meta" },
              { ...googleSettings, source: "GoogleAds" },
            ]),
          }
        );
      } else {
        console.log("advertiserData", advertiserData);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/Advertisers/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtService.getAccessToken()}`,
            },
            method: "POST",
            body: JSON.stringify({
              ...advertiserData,
              entityStatus: "ENTITY_STATUS_ACTIVE",
              tagIds:
                advertiserData.ingageTags?.map((item) => item.tagId) || [],
              countryIds:
                advertiserData.countries?.map((item) => item.countryId) || [],
              categoryIds:
                advertiserData.categories?.map((item) => item.categoryId) || [],
              advertiserCategoryIds:
                advertiserData.categories?.map((item) => item.categoryId) || [],
            }),
          }
        );
        const data = await response.json();
        dispatch(resetNavigation()); // Çalışmıyor. Navigation güncellenmeli.
        handleClose();
      }
      setResponseStatus(true);
      setResponseShow(true);
    } catch (error) {
      console.log(error);
      setResponseStatus(false);
      setResponseShow(true);
    } finally {
      setLoading(false);
    }
  };

  const getAdvertiserCategories = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/AdvertiserCategories?pageIndex=1&pageSize=100`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      setAdvertiserCategories(data.items);
    } catch (error) {
      console.log(error);
    }
  };

  const [ingageTagsOptions, setIngageTagsOptions] = useState([]);
  const getIngageTagsOptions = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/IngageTags?pageIndex=1&pageSize=1000`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      setIngageTagsOptions(data.items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const [categoryOptions, setCategoryOptions] = useState([]);
  const getCategoryOptions = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/IngageCategories?pageIndex=1&pageSize=1000`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      setCategoryOptions(data.items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdvertiserData();
    getAdvertiserSettings();
  }, [state?.item?.advertiserId]);

  return (
    <div>
      {loading ? (
        <div className="mt-[50%]">
          <FuseLoading />
        </div>
      ) : (
        <div>
          <FuseScrollbars className="p-16">
            <div className="flex flex-col">
              <div className="flex justify-between items-end pt-60 mb-40 pl-8">
                <Typography className="text-3xl font-semibold leading-none">
                  Reklamveren Detayları
                </Typography>
              </div>
              <div className="flex flex-col">
                <div className="grid grid-cols-2">
                  <TextField
                    className="mt-8 mb-16 col-span-2"
                    name="advertiserId"
                    required
                    label="Reklamveren ID"
                    autoFocus
                    id="advertiserId"
                    variant="outlined"
                    disabled={disabledInput}
                    fullWidth
                    value={advertiserData?.advertiserId}
                    onChange={handleChangeAdvertiserData}
                  />
                  <TextField
                    className="mt-8 mb-16 col-span-2"
                    name="displayName"
                    required
                    label="Reklamveren Adı"
                    autoFocus
                    id="displayName"
                    variant="outlined"
                    fullWidth
                    value={advertiserData?.displayName}
                    onChange={handleChangeAdvertiserData}
                  />
                  <TextField
                    className="mt-8 mb-16 col-span-2"
                    name="domainUrl"
                    required
                    label="Domain"
                    autoFocus
                    id="domainUrl"
                    variant="outlined"
                    disabled={disabledInput}
                    fullWidth
                    value={advertiserData?.domainUrl}
                    onChange={handleChangeAdvertiserData}
                  />

                  <TextField
                    className="mt-8 mb-16 col-span-2"
                    name="currencyCode"
                    required
                    label="Para Birimi"
                    autoFocus
                    id="currencyCode"
                    variant="outlined"
                    disabled={disabledInput}
                    fullWidth
                    value={advertiserData?.currencyCode}
                    onChange={handleChangeAdvertiserData}
                  />
                  <TextField
                    className="mt-8 mb-16 col-span-2"
                    name="timeZone"
                    required
                    label="Zaman Dilimi"
                    autoFocus
                    id="timeZone"
                    variant="outlined"
                    disabled={disabledInput}
                    fullWidth
                    value={advertiserData?.timeZone}
                    onChange={handleChangeAdvertiserData}
                  />
                  {/* <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Ingage Kategorileri
                    <Typography className="leading-none  mt-8">
                      Ingage Kategorileri
                    </Typography>
                  </Typography> */}
                </div>

                <Divider className="w-full my-24 border-1" dark />
                <Typography className="text-xl font-semibold leading-none mb-10">
                  GoogleAds Onay Ayarları
                </Typography>
                <div className="grid grid-cols-2 gap-16">
                  <TextField
                    label="Günlük Limit Tutarı"
                    type="number"
                    fullWidth
                    value={googleSettings.dailyLimitAmount}
                    onChange={(e) =>
                      setGoogleSettings((prev) => ({
                        ...prev,
                        dailyLimitAmount: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Günlük Limit Onay Süresi (dk)"
                    type="number"
                    fullWidth
                    value={googleSettings.dailyLimitApprovalTimeout}
                    onChange={(e) =>
                      setGoogleSettings((prev) => ({
                        ...prev,
                        dailyLimitApprovalTimeout: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Yüzde Limit Tutarı"
                    type="number"
                    fullWidth
                    value={googleSettings.percentageLimitAmount}
                    onChange={(e) =>
                      setGoogleSettings((prev) => ({
                        ...prev,
                        percentageLimitAmount: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Yüzde Limit Onay Süresi (dk)"
                    type="number"
                    fullWidth
                    value={googleSettings.percentageLimitApprovalTimeout}
                    onChange={(e) =>
                      setGoogleSettings((prev) => ({
                        ...prev,
                        percentageLimitApprovalTimeout: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Kurulum Onay Süresi (dk)"
                    type="number"
                    fullWidth
                    value={googleSettings.installationApprovalTimeout}
                    onChange={(e) =>
                      setGoogleSettings((prev) => ({
                        ...prev,
                        installationApprovalTimeout: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Metrik Değişimi Onay Süresi (dk)"
                    type="number"
                    fullWidth
                    value={googleSettings.metricChangeApprovalTimeout}
                    onChange={(e) =>
                      setGoogleSettings((prev) => ({
                        ...prev,
                        metricChangeApprovalTimeout: e.target.value,
                      }))
                    }
                  />
                </div>

                <Divider className="w-full my-24 border-1" dark />
                <Typography className="text-xl font-semibold leading-none mb-10">
                  Meta Onay Ayarları
                </Typography>
                <div className="grid grid-cols-2 gap-16">
                  <TextField
                    label="Günlük Limit Tutarı"
                    type="number"
                    fullWidth
                    value={metaSettings.dailyLimitAmount}
                    onChange={(e) =>
                      setMetaSettings((prev) => ({
                        ...prev,
                        dailyLimitAmount: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Günlük Limit Onay Süresi (dk)"
                    type="number"
                    fullWidth
                    value={metaSettings.dailyLimitApprovalTimeout}
                    onChange={(e) =>
                      setMetaSettings((prev) => ({
                        ...prev,
                        dailyLimitApprovalTimeout: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Yüzde Limit Tutarı"
                    type="number"
                    fullWidth
                    value={metaSettings.percentageLimitAmount}
                    onChange={(e) =>
                      setMetaSettings((prev) => ({
                        ...prev,
                        percentageLimitAmount: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Yüzde Limit Onay Süresi (dk)"
                    type="number"
                    fullWidth
                    value={metaSettings.percentageLimitApprovalTimeout}
                    onChange={(e) =>
                      setMetaSettings((prev) => ({
                        ...prev,
                        percentageLimitApprovalTimeout: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Kurulum Onay Süresi (dk)"
                    type="number"
                    fullWidth
                    value={metaSettings.installationApprovalTimeout}
                    onChange={(e) =>
                      setMetaSettings((prev) => ({
                        ...prev,
                        installationApprovalTimeout: e.target.value,
                      }))
                    }
                  />
                  <TextField
                    label="Metrik Değişimi Onay Süresi (dk)"
                    type="number"
                    fullWidth
                    value={metaSettings.metricChangeApprovalTimeout}
                    onChange={(e) =>
                      setMetaSettings((prev) => ({
                        ...prev,
                        metricChangeApprovalTimeout: e.target.value,
                      }))
                    }
                  />
                </div>

                <Divider className="w-full my-24 border-1" dark />

                <div className="grid grid-cols-2 gap-x-20">
                  <TextField
                    className="mt-8 mb-16 col-span-2"
                    name="dv360Id"
                    required
                    label="Dv 360 ID"
                    autoFocus
                    id="dv360Id"
                    variant="outlined"
                    fullWidth
                    value={advertiserData.dv360Id}
                    onChange={handleChangeAdvertiserData}
                  />
                  <TextField
                    className="mt-8 mb-16 col-span-2"
                    name="googleAdsId"
                    required
                    label="Google Ads ID"
                    autoFocus
                    id="googleAdsId"
                    variant="outlined"
                    fullWidth
                    value={advertiserData.googleAdsId}
                    onChange={handleChangeAdvertiserData}
                  />
                  <TextField
                    className="mt-8 mb-16 col-span-2"
                    name="metaAdsId"
                    required
                    label="Meta Ads ID"
                    autoFocus
                    id="ingageId"
                    variant="outlined"
                    fullWidth
                    value={advertiserData.metaAdsId}
                    onChange={handleChangeAdvertiserData}
                  />
                  <TextField
                    className="mt-8 mb-16 col-span-2"
                    name="analyticsId"
                    required
                    label="Analytics Property ID"
                    autoFocus
                    id="analyticsId"
                    variant="outlined"
                    fullWidth
                    value={advertiserData.analyticsId}
                    onChange={handleChangeAdvertiserData}
                  />
                  <TextField
                    className="mt-8 mb-16 col-span-2"
                    name="ingageId"
                    required
                    label="Ingage ID"
                    autoFocus
                    id="ingageId"
                    variant="outlined"
                    fullWidth
                    value={advertiserData.ingageId}
                    onChange={handleChangeAdvertiserData}
                  />
                  <Autocomplete
                    options={categoryOptions}
                    fullWidth
                    label="Reklamveren Kategorileri"
                    className="mt-8 mb-16 col-span-2"
                    autoHighlight
                    multiple={true}
                    name="categories"
                    value={
                      advertiserData.categories !== undefined &&
                      advertiserData.categories !== null
                        ? advertiserData.categories
                        : []
                    }
                    key={(item) => item.categoryId}
                    getOptionLabel={(option) => option?.name}
                    defaultValue={[]}
                    onChange={(e, newValue) =>
                      handleChangeAdvertiserData(e, newValue, "categories")
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Reklamveren Kategorileri"
                        name="categories"
                      />
                    )}
                  />
                  <Autocomplete
                    options={countries}
                    fullWidth
                    className="mt-8 mb-16 col-span-2"
                    autoHighlight
                    multiple={true}
                    name="countries"
                    value={
                      advertiserData.countries !== undefined &&
                      advertiserData.countries !== null
                        ? advertiserData.countries
                        : []
                    }
                    key={(countries) => countries.code}
                    getOptionLabel={(option) => option?.name}
                    defaultValue={[]}
                    onChange={(e, newValue) =>
                      handleChangeAdvertiserData(e, newValue, "countries")
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option?.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Ülkeler" name="countries" />
                    )}
                  />
                  <Autocomplete
                    options={ingageTagsOptions}
                    fullWidth
                    label="Ingage Etiketleri"
                    className="mt-8 mb-16 col-span-2"
                    autoHighlight
                    multiple={true}
                    name="ingageTags"
                    value={
                      advertiserData.ingageTags !== undefined &&
                      advertiserData.ingageTags !== null
                        ? advertiserData.ingageTags
                        : []
                    }
                    key={(item) => item.ingageId}
                    getOptionLabel={(option) => option?.name}
                    defaultValue={[]}
                    onChange={(e, newValue) =>
                      handleChangeAdvertiserData(e, newValue, "ingageTags")
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ingage Etiketleri"
                        name="ingageTags"
                      />
                    )}
                  />
                  <FormControl className="ml-10">
                    Durum
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={advertiserData.status}
                      onChange={(e) =>
                        handleChangeAdvertiserData(e, e.target.value, "status")
                      }
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio className="p-0 m-0 select-none" />}
                        label="Pasif"
                        className="py-3 px-10 pl-3 rounded-full  select-none"
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio className="p-0 m-0 select-none" />}
                        label="Aktif"
                        className="py-3 px-10 pl-3 rounded-full  select-none"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <ResponseMessage
                status={responseStatus}
                show={responseShow}
              ></ResponseMessage>
              <Button
                variant="contained"
                color="primary"
                className="mt-8 mb-16"
                onClick={sendAdvertiserData}
              >
                Kaydet
              </Button>
            </div>
          </FuseScrollbars>
        </div>
      )}
    </div>
  );
}

export default withReducer(
  "advertisersDrawer",
  reducer
)(memo(AdvertisersDrawer));
